/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~bootstrap/dist/css/bootstrap.min.css";

@import './assets/styles/colors.scss';
@import './assets/styles/fonts.scss';

html,
body {
  height: 100%;
}

*{
  font-family: "", sans-serif;
}

h2 {
  font-weight: 300 !important;
  font-family: Open Sans, sans-serif !important;
}

p {
  font-family: Open Sans, sans-serif;
}

.valido{
  color: $msg-error-valid;
}

.invalido {
  color: $msg-error-invalid;
}

.large-width {
  max-width: 516px;
  margin-right: 16px;
}

.medium-width {
  max-width: 250px;
  margin-right: 16px;
}

.small-width {
  max-width: 100px;
  margin-right: 16px;
}


/* Usado no menu */
.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 6px !important;
    background-color: lighten(#555, 55%) !important;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(50, 50, 50, .3);
    background-color: #555;
  }
}
