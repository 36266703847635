@font-face {
  font-family: MyriadPro;
  src: url('./../fonts/myriad-pro-8.otf');
}

@font-face {
  font-family: OpenSans;
  src: url('./../fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: OpenSans-Regular;
  src: url('./../fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url('./../fonts/OpenSans-SemiBold.ttf');
}


$padding-font-page-initial: 48px 0px 0px 0px;

$title-font-size: 48px;
$title-font-size-mobile: 34px;

$sub-title-font-size: 24px;
$sub-title-font-size-mobile: 16px;

$resume-tittle-font-size: 13px;
$resume-tittle-font-size-mobile: 10px;

$content-text-table-size: 13px;
$content-text-table-size-mobile: 9px;

$icon-size-small: 16px;
$icon-size-medium: 24px;
$icon-size-large: 32px;


$font-family-page: 'Open Sans', sans-serif;
$font-family: 'Open Sans', sans-serif;
